export const timer = {
  data: () => ({
    timer: null,
  }),
  watch: {
    currentTime (time) {
      if (time === 0) {
        this.stopTimer()
      }
    },
  },

  methods: {
    startTimer () {
      this.timer = setInterval(() => {
        this.currentTime--
      }, 1000)
    },
    stopTimer () {
      clearTimeout(this.timer)
    },
  },

  mounted () {
    this.startTimer()
  },
  destroyed () {
    this.stopTimer()
  },
}
