<template>
  <div class="check-your-email">
    <VAuthContainer class="check-your-email__container">
      <div class="check-your-email__header">
        <div class="check-your-email__title">
          {{ $t('Check your email') }}
        </div>

        <IconUnlock class="check-your-email__icon" />
      </div>

      <div class="check-your-email__text">
        <span>{{ $t('We sent an email to ') }}</span>
        <span class="check-your-email__text_bold">{{ email }}, </span>
        <span>{{ $t(' if this email exists.') }}</span>
      </div>

      <ErrorsLog
        :errorsShowed="errorsShowed"
        :errors="errors"
        :generalError="generalError"
        :booleanRulesForGeneralError="booleanRulesForGeneralError"
        @error-enter="erorrEnter"
        @error-after-leave="errorAfterLeave"
        @general-error-enter="generalErrorEnter"
        @general-error-after-leave="generalErrorAfterLeave"
      />

      <a
        :href="`https://${mailLink}`"
        target="_blank"
        rel="noopener noreferrer"
        class="check-your-email__button-link"
      >
        <VButton>
          {{ $t('Check mail at ') + mailLink }}
        </VButton>
      </a>
    </VAuthContainer>

    <div
      v-if="currentTime"
      class="check-your-email__timer"
    >
      {{ $t('You can resend confirmation email in ') + currentTime + '...' }}
    </div>

    <div
      v-else
      @click="sendResetLinkAgain"
    >
      <VLink
        color="blue"
        :outlined="true"
      >
        <div class="check-your-email__link">
          <IconForward />

          <div>{{ $t('Resend reset link') }}</div>
        </div>
      </VLink>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { errorsLog } from '@/mixins/errorsLog';
import { timer } from '@/mixins/timer';
import { validate } from '@/utils/validate';
import ErrorsLog from '@/components/auth/ErrorsLog';
import VAuthContainer from '@/components/ui/Auth/VAuthContainer';
import VButton from '@/components/ui/Auth/VButton';
import VLink from '@/components/ui/Auth/VLink';
import IconForward from '@/assets/icons/forward-02.svg';
import IconUnlock from '@/assets/icons/unlock.svg';

export default {
  name: 'CheckYourEmail',
  components: {
    ErrorsLog,
    VAuthContainer,
    VButton,
    VLink,
    IconForward,
    IconUnlock,
  },
  mixins: [
    errorsLog,
    timer,
  ],

  data: () => ({
    currentTime: 60,
    timer: null,
  }),
  computed: {
    ...mapState('general', [
      'language',
    ]),

    ...mapState('auth', [
      'email',
      'mailLink',
    ]),

    statusAllFields() {
      return false;
    },
  },

  methods: {
    ...mapActions('auth', [
      'resetToken',
    ]),

    async sendResetLinkAgain() {
      try {
        await this.resetToken({
          email: this.email,
          lang: this.language,
        });

        this.currentTime = 60;
        this.startTimer();
      } catch(e) {
        const error = e.response
          ? e.response.data.message
          : e.message;

        if (error === 'Network Error') {
          return this.setGeneralError(true, validate.generalErrors.connectionFalse);
        }

        this.setGeneralError(true, validate.generalErrors.tryAgain);
      }
    },
  },

  metaInfo() {
    return {
      title: this.$t('Check your email'),
    };
  },
  i18n: {
    messages: {
      'en': {
        'Check your email': 'Check your email',
        'We sent an email to ': 'We sent an email to ',
        ' if this email exists.': ' if this email exists, which contains a link to reset your password.',
        'Check mail at ': 'Check mail at ',
        'You can resend confirmation email in ': 'You can resend confirmation email in ',
        'Resend reset link': 'Resend reset link',
      },
      'ru': {
        'Check your email': 'Проверьте свою электронную почту',
        'We sent an email to ': 'Мы отправили письмо на ',
        ' if this email exists.': ' в данном письме Вы получите ссылку для сброса своего пароля.',
        'Check mail at ': 'Открыть почту на ',
        'You can resend confirmation email in ': 'Вы можете повторно отправить письмо через ',
        'Resend reset link': 'Повторно отправить письмо',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.check-your-email {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.check-your-email__container {
  display: grid;
  margin-bottom: 20px;
  padding: 26px;
  height: max-content;
  color: #4D505E;
}

.check-your-email__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.check-your-email__title {
  font-size: 18px;
  font-weight: 600;
  font-variation-settings: 'wght' 600;
  line-height: 25px;
}

.check-your-email__text {
  margin-bottom: 20px;
  font-size: 12px;
  font-weight: 400;
  font-variation-settings: 'wght' 400;
  line-height: 160%;
}

.check-your-email__text_bold {
  font-weight: 700;
  font-variation-settings: 'wght' 700;
}

.check-your-email__button-link {
  width: 100%;
}

.check-your-email__timer {
  font-size: 14px;
  font-weight: 400;
  font-variation-settings: 'wght' 400;
  line-height: 19px;
  color: #A6ACBF;
}

.check-your-email__link {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-column-gap: 4px;
  align-items: center;

  svg {
    height: auto;
    width: 12px;

    rect,
    path {
      fill: #9CB1F7;
    }
  }

  &:hover {
    svg {
      rect,
      path {
        fill: #6788F3;
      }
    }
  }

  &:active {
    svg {
      rect,
      path {
        fill: #5B78D6;
      }
    }
  }
}
</style>
